// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__checkbox___2to-S{display:none;position:absolute}.styles__label___2IcaA{width:50px;height:26px;background-color:var(--primary-black);display:flex;border-radius:50px;align-items:center;justify-content:space-between;padding:5px;position:relative;transform:scale(1.5);cursor:pointer;left:10px;user-select:none;margin-left:10px}.styles__label___2IcaA img{height:18px}.styles__ball___rZMOY{width:20px;height:20px;background-color:var(--primary-white);position:absolute;top:3px;left:3px;border-radius:50%;transition:transform .2s linear}.styles__checkbox___2to-S:checked+.styles__label___2IcaA .styles__ball___rZMOY{transform:translateX(24px)}.styles__hide___3qvEF{opacity:0}@media only screen and (max-width: 1480px){.styles__label___2IcaA{left:0;background-color:var(--toggler-bg);margin-left:5px}}", ""]);
// Exports
exports.locals = {
	"checkbox": "styles__checkbox___2to-S",
	"label": "styles__label___2IcaA",
	"ball": "styles__ball___rZMOY",
	"hide": "styles__hide___3qvEF"
};
module.exports = exports;
