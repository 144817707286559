// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__container____YGch{position:fixed;top:0;left:0;z-index:1000;height:5px;background-color:var(--primary-green);transition:ease-in-out 25ms}", ""]);
// Exports
exports.locals = {
	"container": "styles__container____YGch"
};
module.exports = exports;
