import React, { createContext, useState, useEffect, useContext } from 'react';

import { checkAgentIsDesktop } from 'utils/mobile';

interface IsMobileContextType {
  isMobile: boolean;
}

export const IsMobileContext = createContext<IsMobileContextType>({
  isMobile: false,
});

export const useIsMobile = () => useContext(IsMobileContext);

const IsMobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    setIsMobile(!checkAgentIsDesktop() || window.innerWidth < 900);
  };

  useEffect(() => {
    checkIsMobile();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, [checkIsMobile]);

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      {children}
    </IsMobileContext.Provider>
  );
};

export default IsMobileProvider;
