// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html{--primary-green: #33c889;--lighter-grey: #7c7575;--light-grey: #c4c4c4;--dark-grey: #e3dede;--primary-black: #cdcdcd;--primary-white: #000;--primary-pink: #fb88aa;--default-shadow: rgba(255, 255, 255, 0.16);--stronger-shadow: rgba(255, 255, 255, 0.25);--toggler-bg: #7c7575;--background-fore-color: #101010;--global-h1: 36px;--global-h1-margin-bottom: 50px;--global-container-padding: 10rem}html[data-theme=light]{--primary-green: #33c889;--lighter-grey: #e3dede;--light-grey: #c4c4c4;--dark-grey: #7c7575;--primary-black: #000000;--primary-white: #ffffff;--primary-pink: #fb88aa;--default-shadow: rgba(0, 0, 0, 0.16);--stronger-shadow: rgba(0, 0, 0, 0.25);--toggler-bg: #7c7575;--background-fore-color: #eeeeee}*{margin:0;padding:0;box-sizing:border-box}html{font-size:62.5%}body{font-family:\"Roboto\",system-ui}.styles__app___l-CyE{overflow:auto;background-color:var(--primary-white)}", ""]);
// Exports
exports.locals = {
	"app": "styles__app___l-CyE"
};
module.exports = exports;
