import React, { useState } from 'react';

import TopNav from 'components/topNav';
import ContentBlock from 'components/contentBlock';
import { NAV_ITEMS } from 'utils/global';
import IsMobileProvider from 'hooks/useIsMobile';

import styles from './styles.scss';

const App = () => {
  const [isTop, setIsTop] = useState(true);
  const [activeNav, setActiveNav] = useState(NAV_ITEMS.items.home.id);

  return (
    <div className={styles.app}>
      <IsMobileProvider>
        <TopNav isTop={isTop} activeNav={activeNav} />

        <ContentBlock setIsTop={setIsTop} setActiveNav={setActiveNav} />
      </IsMobileProvider>
    </div>
  );
};

export default App;
