import { useState } from 'react';

const useLocalStorage = <T>(
  keyName: string,
  defaultValue: T,
): [T, (newState: T) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      }
      window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      // Handle setItem error
    }

    setStoredValue(newValue);
  };

  return [storedValue as T, setValue];
};

export default useLocalStorage;
