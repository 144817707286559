import React, { FC, useRef, useState, useEffect } from 'react';

import ScrollProgressBar from 'components/scrollProgressBar/ScrollProgressBar';
import ThemeToggle from 'components/topNav/themeToggle';
import { scrollToTarget, scrollToTop, getScrollPercent } from 'utils/scroll';
import { NAV_ITEMS, SOCIAL_LINKS } from 'utils/global';
import { useIsMobile } from 'hooks/useIsMobile';

import styles from './styles.scss';

interface TopNavProps {
  isTop: boolean;
  activeNav: number | null;
}

const TopNav: FC<TopNavProps> = ({ isTop, activeNav }) => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const { isMobile } = useIsMobile();
  const topNavRef = useRef<HTMLDivElement>(null);

  const toggleNavMenu = () => setShowNavMenu(!showNavMenu);

  const onNavClicked = navItem => {
    scrollToTarget(NAV_ITEMS.items[navItem].name);
  };

  const handleNavToggle = e => {
    if (topNavRef.current && !topNavRef?.current?.contains(e.target)) {
      setShowNavMenu(false);
    }
    e.stopPropagation();
  };

  const calcScrollProgress = () => {
    const scrollPercentage = getScrollPercent();
    setScrollProgress(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', calcScrollProgress);

    if (isMobile) {
      window.addEventListener('click', handleNavToggle);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', calcScrollProgress);
        window.removeEventListener('click', handleNavToggle);
      }
    };
  }, [isMobile, calcScrollProgress]);

  return (
    <div
      ref={topNavRef}
      className={`${styles.container} ${isTop ? styles.navAtTop : ''}`}>
      <ScrollProgressBar progressPercentage={scrollProgress} />

      <div
        className={`${styles.logo} ${isTop ? styles.logoAtTop : ''}`}
        onClick={scrollToTop}
      />

      <div className={styles.divider} />

      <div className={styles.links}>
        <a
          className={`${styles.link} ${styles.github}`}
          href={SOCIAL_LINKS.github}
          target="_blank"
          rel="noreferrer">
          {' '}
        </a>
        <a
          className={`${styles.link} ${styles.linkedIn}`}
          href={SOCIAL_LINKS.linkedIn}
          target="_blank"
          rel="noreferrer">
          {' '}
        </a>
        <a
          className={`${styles.link} ${styles.medium}`}
          href={SOCIAL_LINKS.medium}
          target="_blank"
          rel="noreferrer">
          {' '}
        </a>
      </div>

      {isMobile ? (
        <>
          <div className={styles.navBurger} onClick={toggleNavMenu} />

          {showNavMenu && (
            <div className={styles.navMenu}>
              {NAV_ITEMS.order.map((item, index) => {
                // No need to show Home nav, keep nav items simple and short
                if (index === 0) return null;
                const isActiveNav = NAV_ITEMS.items[item].id === activeNav;
                return (
                  <div
                    className={`${styles.mobileItem} ${
                      isActiveNav ? styles.active : ''
                    }`}
                    key={`${NAV_ITEMS.items[item].name}`}
                    onClick={() => {
                      toggleNavMenu();
                      onNavClicked(item);
                    }}>
                    {`${NAV_ITEMS.items[item].name}`}
                  </div>
                );
              })}
              <div className={styles.mobileItem}>
                <ThemeToggle />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={styles.navItems}>
          {NAV_ITEMS.order.map((item, index) => {
            // No need to show Home nav, keep nav items simple and short
            if (index === 0) return null;
            const isActiveNav = NAV_ITEMS.items[item].id === activeNav;
            return (
              <div
                className={`${styles.item} ${isActiveNav ? styles.active : ''}`}
                key={`${NAV_ITEMS.items[item].name}`}
                onClick={() => onNavClicked(item)}>
                {`${NAV_ITEMS.items[item].name}`}
              </div>
            );
          })}

          <ThemeToggle />
        </div>
      )}
    </div>
  );
};

export default TopNav;
