import Scroll, { scroller } from 'react-scroll';

export const scrollToTarget = (
  scrollTarget: string,
  scrollOption = {
    duration: 800,
    delay: 0,
    smooth: 'easeOutQuint',
    offset: -20,
  },
) => {
  scroller.scrollTo(scrollTarget, scrollOption);
};

export const toggleVisibilityWhenScrolledTop = (
  isShown: boolean,
  setShown: (param: boolean) => void,
  yOffsetIndex: number = 400,
) => {
  if (!isShown && window.pageYOffset > yOffsetIndex) {
    setShown(true);
  } else if (isShown && window.pageYOffset <= yOffsetIndex) {
    setShown(false);
  }
};

export const scrollToTop = () => {
  const scroll = Scroll.animateScroll;
  scroll.scrollToTop({
    duration: 800,
    delay: 0,
    smooth: 'easeOutQuint',
  });
};

export const isInViewport = el => {
  const rect = el.getBoundingClientRect();
  const { top, bottom } = rect;
  const windowHeight = window.innerHeight;

  const isInUpperViewport = top >= 0 && top <= (1 / 2) * windowHeight;
  const isInLowerViewport = top < 0 && bottom > (1 / 2) * windowHeight;

  return isInUpperViewport || isInLowerViewport;
};

export const getScrollPercent = () => {
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight ||
    0;

  const windowYScroll =
    window.pageYOffset ||
    document.body.scrollTop ||
    document.documentElement.scrollTop ||
    0;

  const docHeight = Math.max(
    document.body.scrollHeight || 0,
    document.documentElement.scrollHeight || 0,
    document.body.offsetHeight || 0,
    document.documentElement.offsetHeight || 0,
    document.body.clientHeight || 0,
    document.documentElement.clientHeight || 0,
  );

  const res = (windowYScroll / (docHeight - windowHeight)) * 100;

  return res < 2 ? 0 : res;
};
