// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles__container___bYRl1{position:fixed;bottom:40px;right:40px;z-index:999;transform:translateY(-50%)}.styles__list___2Plnh{display:flex;flex-direction:column;transition:opacity .3s;animation:styles__fadeIn___1ls1F .3s}@keyframes styles__fadeIn___1ls1F{from{opacity:0}to{opacity:1}}", ""]);
// Exports
exports.locals = {
	"container": "styles__container___bYRl1",
	"list": "styles__list___2Plnh",
	"fadeIn": "styles__fadeIn___1ls1F"
};
module.exports = exports;
