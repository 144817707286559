import React, { useEffect, useState } from 'react';

import { toggleVisibilityWhenScrolledTop } from 'utils/scroll';
import BackTopButton from './backTopButton';

import styles from './styles.scss';

const SideNav = () => {
  const [showSideNav, setShowSideNav] = useState(false);

  useEffect(() => {
    const checkScroll = () =>
      toggleVisibilityWhenScrolledTop(showSideNav, setShowSideNav, 650);

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  });

  return (
    <div className={styles.container}>
      {showSideNav && (
        <div className={styles.list}>
          <BackTopButton />
        </div>
      )}
    </div>
  );
};

export default SideNav;
