import React, { useEffect, useRef } from 'react';

import { THEME_LOCAL_STORAGE_KEY, THEMES } from 'utils/global';
import useLocalStorage from 'hooks/useLocalStorage';

import Moon from 'res/moon.svg';
import Sun from 'res/sun.svg';
import clickSound from 'res/sound/click.mp3';

import styles from './styles.scss';

// Ref: https://codepen.io/PaulinaSurazynska/pen/bGVpBOb
const ThemeToggle = () => {
  const [mode, setMode] = useLocalStorage(THEME_LOCAL_STORAGE_KEY, THEMES.dark);
  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', mode);

    if (mode === THEMES.light && checkboxRef.current) {
      checkboxRef.current.checked = true;
    }
  }, []);

  const handleSwitch = () => {
    const audio = new Audio(clickSound);
    audio.play();

    document.documentElement.setAttribute(
      'data-theme',
      mode === THEMES.dark ? THEMES.light : THEMES.dark,
    );

    setMode(mode === THEMES.dark ? THEMES.light : THEMES.dark);
  };

  return (
    <>
      <input
        className={styles.checkbox}
        type="checkbox"
        id="toggle"
        onClick={handleSwitch}
        ref={checkboxRef}
      />
      <label className={styles.label} htmlFor="toggle">
        <img
          className={mode === THEMES.dark ? styles.hide : undefined}
          src={Sun}
          alt="light-mode"
          loading="lazy"
        />
        <img
          className={mode === THEMES.dark ? undefined : styles.hide}
          src={Moon}
          alt="dark-mode"
          loading="lazy"
        />
        <div className={styles.ball} />
      </label>
    </>
  );
};

export default ThemeToggle;
