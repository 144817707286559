// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../res/backTop.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".styles__container___1CyzR{display:flex;justify-content:center;align-items:center;width:40px;height:40px;cursor:pointer;opacity:.5;background-color:var(--primary-black);mask-size:contain;mask-position:center;mask-repeat:no-repeat;-webkit-mask-size:contain;-webkit-mask-position:center;-webkit-mask-repeat:no-repeat;mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.styles__container___1CyzR:hover{opacity:1}", ""]);
// Exports
exports.locals = {
	"container": "styles__container___1CyzR"
};
module.exports = exports;
