import React, { FC, useEffect, useRef, lazy, Suspense } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import SideNav from 'components/sideNav';
import { isInViewport } from 'utils/scroll';
import { NAV_ITEMS } from 'utils/global';
import { useIsMobile } from 'hooks/useIsMobile';

import styles from './styles.scss';

const Landing = lazy(
  () => import(/* webpackChunkName: "landing" */ '../landing'),
);
const MyHistory = lazy(
  () => import(/* webpackChunkName: "history" */ '../myHistory'),
);
const CodeWorks = lazy(
  () => import(/* webpackChunkName: "codeWorks" */ '../codeWorks'),
);
const ArtWorks = lazy(
  () => import(/* webpackChunkName: "artWorks" */ '../artWorks'),
);
const Podcast = lazy(
  () => import(/* webpackChunkName: "podcast" */ '../podcast'),
);
const MyVideos = lazy(
  () => import(/* webpackChunkName: "videos" */ '../myVideos'),
);
const Footer = lazy(
  () => import(/* webpackChunkName: "footer" */ '../footerBlock'),
);

interface ContentBlockProps {
  setIsTop: Function;
  setActiveNav: Function;
}

const ContentBlock: FC<ContentBlockProps> = ({ setIsTop, setActiveNav }) => {
  const { isMobile } = useIsMobile();

  const landingRef = useRef(null);
  const historyRef = useRef(null);
  const codeRef = useRef(null);
  const artRef = useRef(null);
  const podcastRef = useRef(null);
  const videosRef = useRef(null);

  const checkCurrentRef = () => {
    // Place the scroll-y value into html tag's style property
    document.documentElement.style.setProperty(
      '--scroll-y',
      `${window.scrollY}px`,
    );

    setIsTop(window.scrollY < 10);

    if (landingRef.current && isInViewport(landingRef.current)) {
      setActiveNav(NAV_ITEMS.items.home.id);
      document.title = 'Home | Dave Huang';
    }

    if (historyRef.current && isInViewport(historyRef.current)) {
      setActiveNav(NAV_ITEMS.items.history.id);
      document.title = 'History | Dave Huang';
    }

    if (codeRef.current && isInViewport(codeRef.current)) {
      setActiveNav(NAV_ITEMS.items.code.id);
      document.title = 'Code Works | Dave Huang';
    }

    if (artRef.current && isInViewport(artRef.current)) {
      setActiveNav(NAV_ITEMS.items.art.id);
      document.title = 'Art Works | Dave Huang';
    }

    if (podcastRef.current && isInViewport(podcastRef.current)) {
      setActiveNav(NAV_ITEMS.items.podcast.id);
      document.title = 'Podcast | Dave Huang';
    }

    if (videosRef.current && isInViewport(videosRef.current)) {
      setActiveNav(NAV_ITEMS.items.videos.id);
      document.title = 'Videos | Dave Huang';
    }
  };

  const checkScrollPosition = () => {
    checkCurrentRef();
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, [checkScrollPosition]);

  return (
    <Suspense
      fallback={
        <div className={styles.spinner}>
          <BeatLoader color="#33c889" size="20" />
        </div>
      }>
      <Landing landingRef={landingRef} />
      <MyHistory myHistoryRef={historyRef} />
      <CodeWorks codeWorksRef={codeRef} />
      <ArtWorks artWorksRef={artRef} />
      <Podcast podcastRef={podcastRef} />
      <MyVideos videosRef={videosRef} />

      {!isMobile && <SideNav />}

      <Footer />
    </Suspense>
  );
};

export default ContentBlock;
