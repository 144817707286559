import React from 'react';

import { scrollToTop } from 'utils/scroll';

import styles from './styles.scss';

const BackTopButton = () => {
  return <div className={styles.container} onClick={scrollToTop} />;
};

export default BackTopButton;
