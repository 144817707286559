import React, { FC } from 'react';

import styles from './styles.scss';

interface ScrollProgressBarProps {
  progressPercentage: number;
}

const ScrollProgressBar: FC<ScrollProgressBarProps> = ({
  progressPercentage,
}) => {
  return (
    <div
      className={styles.container}
      style={{ width: `${progressPercentage}%` }}
    />
  );
};

export default ScrollProgressBar;
