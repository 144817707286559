import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import App from 'components/app';

const render = (Component, rootElement) => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    rootElement,
  );
};

const rootElement = document.getElementById('react-app');

render(App, rootElement);

if (module.hot) {
  module.hot.accept('./components/app', () => {
    render(App, rootElement);
  });
}
